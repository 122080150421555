import React from 'react';
import { Link } from 'gatsby';

const Footer = () => {
    return(
        <footer>
            <p>
                Tous droits réservés, Saillon vins
            </p>
            <p>
                <Link to="/politique">Politique de confidentialité</Link>
            </p>
        </footer>
    )
}

export default Footer;
