import React from 'react';

import NavBar from './NavBar';
import Footer from './Footer';

import CookieAccept from './CookieConsent';

export default function Layout({ children }) {
    return (
      <main>
        <NavBar />
        <div className="flexThisBox">
          {children}
        </div>
        <Footer />
        <CookieAccept />
      </main>
    )
  }