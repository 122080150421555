import React, { useState } from 'react';

import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Cross as Hamburger } from 'hamburger-react'


const NavBar = () => {

    const [isOpen, setOpen] = useState(false);

    return(
        <header>
            <nav className={ `${isOpen ? 'mobile-menu' : '' }`}>
            
                <div className="logo">
                <Link to="/">        
                    <StaticImage src="../images/caveau_logo.jpg"
                     alt="logo" 
                     placeholder="blurred"
                     height={120}
                     layout="fixed" />         
                </Link>     
                </div>
            

                <div className="logo-mobile">
                <Link to="/">
                    <StaticImage src="../images/caveau_logo.jpg"
                     alt="logo" 
                     placeholder="blurred"
                     height={90}
                     layout="fixed" />
                </Link>
                </div>

                <div className="hamburger-button">
                    <Hamburger toggled={isOpen} toggle={setOpen} />
                </div>
                    <ul>
                        <li><Link activeClassName="active-link" to="/">Accueil</Link></li>
                        <li><Link activeClassName="active-link" to="/carte">Notre carte</Link></li>
                        <li><Link activeClassName="active-link" to="/vignerons">Vignerons</Link></li>
                        <li><Link activeClassName="active-link" to="/galerie">Galerie</Link></li>
                        <li><Link activeClassName="active-link" to="/contact">Contact</Link></li>
                    </ul>
            </nav>
        </header>
    )
}

export default NavBar;
