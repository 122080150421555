import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieAccept = () => {
  return (
    <CookieConsent
      enableDeclineButton
      flipButtons
      declineButtonText="Je refuse"
      location="bottom"
      buttonText="J'accepte"
      cookieName="consentement_caveau_saillon"
      style={{  background: 'black',
                fontFamily: 'Karla',
                fontSize: '14px' }}
      buttonStyle={{
        margin: '10px 10px 10px 0',
        fontSize: '14px',
        fontFamily: 'Karla'
      }}
      declineButtonStyle={{
        margin: '10px 10px 10px 0',
        fontSize: '14px',
        fontFamily: 'Karla'
      }}
      expires={450}
    >
      Ce site web utilise des cookies pour améliorer votre expérience
    </CookieConsent>
  );
};
export default CookieAccept;